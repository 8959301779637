import React from "react";
import TwoColumnPage from "../components/TwoColumnPage";

export default (props) => {
    return (
        <TwoColumnPage
            title="De Praktijk"
            shortTitle="De Praktijk"
            imageSrc={process.env.PUBLIC_URL + "/images/specialisaties/009.jpg"}
            imageAlt="#staysafe"
        >
            <p>Ik werk voornamelijk met kinderen maar ook volwassenen kunnen bij mij terecht.  Samen gaan we op zoek naar een persoonlijke oplossing.</p>
            <p>Begeleiding van kinderen met:</p>
            <ul>
                <li>Neurologische problemen</li>
                <li>Ontwikkelingsachterstand en –vertraging</li>
                <li>Leer- en schrijfproblemen</li>
                <li>Concentratieproblemen</li>
                <li>Psychomotorische problemen</li>
                <li>Spanningsproblemen</li>
                <li>Motorische (zowel grove als fijne) problemen</li>
                <li>Algemene bewegingsproblemen en klachten</li>
                <li>Ademhalingsproblemen</li>
            </ul>
            <p>Begeleiding van volwassen met:</p>
            <ul>
                <li>Oedemen</li>
                <li>Ademhalingsproblemen</li>
                <li>Relaxatietherapie</li>
                <li>Algemene klachten</li>
            </ul>
        </TwoColumnPage>
    );
};
