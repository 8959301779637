import React from "react";
import SpecialisatiesPage from "../../components/SpecialisatiesPage";

export default (props) => {
    return (
        <SpecialisatiesPage
            title="VKOH - Hendrickx Methode"
            shortTitle="VKOH - Hendrickx Methode"
            imageSrc={process.env.PUBLIC_URL + "/images/home/home_08.jpg"}
            imageAlt="VKOH - Hendrickx Methode"
        >
            <p>
                Kritische ontwikkelingsbegeleiding houdt rekening met de
                problematiek (lateralisatieproblemen, concentratieproblemen,
                schrijfproblemen,…) zelf als met het hele gedragssysteem. Men
                vertrekt daarbij vanuit de reeds gekende mogelijkheden en bouwt
                dan verder uit, gebruikmakend van het lichaam.
            </p>
            <p>
                De begeleider benadert de persoon als een
                persoon-in-ontwikkeling, die zelf bepaalt hoe en wanneer hij
                zich aanpast aan de kenmerken, veranderingen en actuele eisen
                van de omgeving. Daarom wordt er ook eerder van begeleiding
                gesproken dan van therapie.
            </p>
            <p>
                Kritisch begeleiden betekent dat men altijd vertrekt van wat het
                kind op dat moment kan en niet van wat hij normaal zou moeten
                kunnen. De begeleider vertrekt dan ook altijd vanuit een
                "haalbare uitdaging" om verder te bouwen op het ritme van het
                kind.
            </p>
            <p>
                Meer info:{" "}
                <a
                    href="https://www.vkoh.be"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    www.vkoh.be
                </a>
            </p>
        </SpecialisatiesPage>
    );
};
