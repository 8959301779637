import React from "react";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import * as Constants from "../Constants";

export default (props) => {
    return (
        <div>
            <Header title={Constants.siteName} />
            <Main />
            <Footer />
        </div>
    );
};