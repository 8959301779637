import React from "react";
import SpecialisatiesPage from "../../components/SpecialisatiesPage";

export default (props) => {
    return (
        <SpecialisatiesPage
            title="Lymfedrainage"
            shortTitle="Lymfedrainage"
            imageSrc={process.env.PUBLIC_URL + "/images/specialisaties/007.jpg"}
            imageAlt="Lymfedrainage"
        >
            <p>
                Manuele lymfedrainage is een massagetechniek die gebruikt wordt
                om het lymfesysteem te stimuleren. Het doel van deze techniek is
                het (zicht- en onzichtbare oedeem) vocht beter en sneller via de
                lymfebanen af te voeren.
            </p>
            <p>
                Het heeft ook als doel de vochtdoorstroming te stimuleren,
                stress te verlagen en het hormonale stelsel te optimaliseren. We
                kunnen pas van een lichaam in evenwicht spreken als de
                waterhuishouding in uitstekende conditie verkeert!
            </p>
            <p>
                Wie komt hiervoor in aanmerking? Patiënten met chronisch
                lymfoedeem (bv zware benen,…), zwellingen na een trauma of
                verstuiking (cfr gezwollen enkel), operatie of bestraling (cfr
                borstkanker, prostaatkanker)
            </p>
        </SpecialisatiesPage>
    );
};
