import React from "react";
import SpecialisatiesPage from "../../components/SpecialisatiesPage";

export default (props) => {
    return (
        <SpecialisatiesPage
            title="Pre- en postnatale kinesitherapie"
            shortTitle="Pre- en postnatale kinesitherapie"
            imageSrc={process.env.PUBLIC_URL + "/images/specialisaties/015.jpg"}
            imageAlt="Pre- en postnatale kinesitherapie"
        >
            <p>Zwanger zijn is een bijzondere periode in je leven waarin je lichaam heel wat veranderingen ondergaat. Soms gaan deze gepaard met de nodige kwaaltjes (rugklachten, bekkeninstabiliteit, oedeem,…).</p>
            <p>Zwangerschapskinesitherapie kan u zowel voor als na de bevalling helpen om deze kwaaltjes te voorkomen, te verminderen en te behandelen.</p>
            <p>Prenatale kinesitherapie helpt je om je beter voor te bereiden op de bevalling door:</p>
            <ul>
                <li>Het geven van tips en advies</li>
                <li>Het geven van aangepaste oefeningen en houdingscorrecties</li>
                <li>Het aanleren van ademhalings- en perstechnieken</li>
                <li>Het aanleren van bekkenbodemspieroefeningen om het riscio op urineverlies te verkleinen</li>
            </ul>
            <p>Postnatale kinesitherapie helpt je met:</p>
            <ul>
                <li>Een optimaal herstel</li>
                <li>Eventuele complicaties na de bevalling</li>
                <li>De wederopbouw van spierkracht en fysieke conditie</li>
                <li>Het hervatten van sportactiviteiten </li>
            </ul>
        </SpecialisatiesPage>
    );
};
