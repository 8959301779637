import React from "react";
import { Switch, Route } from "react-router-dom";
import Page from "./Page";
import Home from "../views/Home";
import Contact from "../views/Contact";
import Specialisaties from "../views/Specialisaties";
import BabyKinderrevalidatie from "../views/specialisaties/BabyKinderrevalidatie";
import BobathTherapie from "../views/specialisaties/BobathTherapie";
import VkohHendrickxMethode from "../views/specialisaties/VkohHendrickxMethode";
import Kinesiotaping from "../views/specialisaties/Kinesiotaping";
import Lymfedrainage from "../views/specialisaties/Lymfedrainage";
import Schrijfmotoriek from "../views/specialisaties/Schrijfmotoriek";
import Psychomotoriek from "../views/specialisaties/Psychomotoriek";
import PreEnPostnataleKinesitherapie from "../views/specialisaties/PreEnPostnataleKinesitherapie";
import Praktijk from "../views/Praktijk";
import PraktischeInfo from "../views/PraktischeInfo";

const NoMatch = ({ location }) => (
    <Page title="404 | Oeps, niet gevonden">
        <p>We konden niets vinden voor deze URL:</p>
        <p>
            <code>{location.pathname}</code>
        </p>
        <p>
            <a href="/">Ga terug naar de homepage</a>
        </p>
    </Page>
);

const Main = () => (
    <main>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/specialisaties/baby-kinderrevalidatie" component={BabyKinderrevalidatie} />
            <Route path="/specialisaties/bobath-therapie" component={BobathTherapie} />
            <Route path="/specialisaties/vkoh-hendrickx-methode" component={VkohHendrickxMethode} />
            <Route path="/specialisaties/kinesiotaping" component={Kinesiotaping} />
            <Route path="/specialisaties/lymfedrainage" component={Lymfedrainage} />
            <Route path="/specialisaties/schrijfmotoriek" component={Schrijfmotoriek} />
            <Route path="/specialisaties/psychomotoriek" component={Psychomotoriek} />
            <Route path="/specialisaties/pre-en-postnatale-kinesitherapie" component={PreEnPostnataleKinesitherapie} />
            <Route path="/specialisaties" component={Specialisaties} />
            <Route path="/praktijk" component={Praktijk} />
            <Route path="/praktische-info" component={PraktischeInfo} />
            <Route path="/contact" component={Contact} />
            <Route component={NoMatch} />
        </Switch>
    </main>
);

export default Main;
