import React from "react";
import SpecialisatiesPage from "../../components/SpecialisatiesPage";

export default (props) => {
    return (
        <SpecialisatiesPage
            title="Baby- en kinderrevalidatie"
            shortTitle="Baby- en kinderrevalidatie"
            imageSrc={process.env.PUBLIC_URL + "/images/specialisaties/004.jpg"}
            imageAlt="Baby- en kinderrevalidatie"
        >
            <p>
                Naast de psychomotoriek en de neuromotoriek dient er zich binnen
                de kinderkinesitherapie ook nog oog te zijn voor de algemene
                problematiek.
            </p>
            <ul>
                <li>
                    Kinderorthopedie: heupdysplasie, torticollis, fibromatosis
                    colli, operatieve ingrepen, fracturen, traumatische
                    letsels,…
                </li>
                <li>
                    Cardio- respiratoire revalidatie: oa mucovisidose, RSV,
                    bronchodysplasie, specifieke hartafwijkingen.{" "}
                </li>
                <li>
                    Psychosociale training: relaxatie, psychosomatische
                    problemen, stress, spanningshoofdpijn,…
                </li>
            </ul>
        </SpecialisatiesPage>
    );
};
