import React from "react";
import SpecialisatiesPage from "../../components/SpecialisatiesPage";

export default (props) => {
    return (
        <SpecialisatiesPage
            title="Bobath-therapie"
            shortTitle="Bobath-therapie"
            imageSrc={process.env.PUBLIC_URL + "/images/specialisaties/006.jpg"}
            imageAlt="Bobath-therapie"
        >
            <p>
                De behandeling richt zich enerzijds op baby's, peuters en
                kleuters die de ontwikkelingsmijlpalen wat later bereiken of
                niet bereiken. Met mijlpalen bedoelen we de leeftijd waarop
                gerold, gekropen, gestapt wordt…
            </p>
            <p>
                Normaal moeten deze handelingen niet aangeleerd worden maar bij
                kinderen met een vertraagde ontwikkeling zien we dat ze er uit
                zichzelf niet toe komen. Bij deze kinderen zien we vaak het
                gebruik van andere strategieën om zich voort te bewegen cfr
                billenschuiven, blijven huilen met houdingsverandering als doel
                (cfr kinderen die niet graag op de buik liggen en niet kunnen
                rollen), staan verkiezen boven zitten of lig.
            </p>
            <p>
                De echte oorzaak van deze vertraagde ontwikkeling is niet
                gekend. Ze is mogelijk gelinkt aan prematuriteit, asymmetrie,
                reflux, voorkeurshouding of tonusonevenwicht.
            </p>

            <p>
                Anderzijds hebben we kinderen met bekende neurologische
                problemen, denk maar aan kinderen met Cerebral Palsy, CVA,
                hersentrauma (niet aangeboren), plexus brachialis letsel,
                spieraandoeningen, …
            </p>
            <p>
                Het reguleren van de tonus, het gebruik van steun- en
                evenwichtsreacties in combinatie met het aanleren van nieuwe
                strategieën maakt het voor het kind mogelijk om zijn houding en
                beweging beter te controleren. Zo krijgen zij ook de kans om de
                leefwereld rondom hun optimaal te beleven.
            </p>
            <p>
                De specialisatie die hiervoor gebruikt wordt is Bobath-therapie.
            </p>
        </SpecialisatiesPage>
    );
};
