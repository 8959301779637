import React from "react";
import SocialIconLink from "./SocialIconLink";
import { css } from "glamor";

export default (props) => {

    let socialIconsContainerCss = css(
        {
            paddingTop: "10px",
            float: "right",
        },
        {
            "@media(max-width: 875px)": {
                float: "none",
                textAlign: "center",
            },
        }
    );

    return (
        <div className={socialIconsContainerCss}>
            <nobr>
                <SocialIconLink url="mailto:isabel.lecluyse@telenet.be" />
            </nobr>
        </div>
    );

};