import React, { Component } from "react";
import Page from "../components/Page";
import AddressInfo from "../components/AddressInfo";
import ContactInfo from "../components/ContactInfo";
import { css } from "glamor";

class Contact extends Component {
    componentDidMount() {
        let center = [50.7722542, 3.2722014];

        const script = document.createElement("script");
        script.src = process.env.PUBLIC_URL + "/tomtom-sdk/tomtom.min.js";
        document.body.appendChild(script);
        script.async = true;
        script.onload = function () {
            var map = window.tomtom.L.map("map", {
                source: "vector",
                key: "VSCVwhMCuS9a9CMRdYEuFtEsa6INited",
                center: center,
                basePath: "/tomtom-sdk",
                zoom: 15,
            });
            var marker = window.tomtom.L.marker(center).addTo(map);
            var popup = window.tomtom.L.popup({ closeButton: false })
                .setLatLng(center)
                .setContent("<p><b>Isabel Lecluyse</b><br/>Munkendoornstraat 294<br/>8510 Bellegem (Kortrijk)</p>");
            marker.bindPopup(popup).openPopup();
        };
    }

    render() {
        let mapCss = css({
            width: "100%",
            height: "480px",
        });

        return (
            <Page title="Contacteer Me" shortTitle="Contact">
                <div id="map" className={mapCss + " shadow"}></div>
                <div className="center">
                    <AddressInfo />
                    <ContactInfo />
                </div>
            </Page>
        );
    }
}

export default Contact;
