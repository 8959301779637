import React from "react";
import Page from "../components/Page";
import { css } from "glamor";
import SpecialisatiesGridItem from '../components/SpecialisatieGridItem';

export default (props) => {

    let gridDivCss = css(
        {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            padding: "0px",
            gridGap: "10px",
        },
        {
            "@media(max-width: 875px)": {
                paddingTop: 22,
                paddingBottom: 22,
                gridTemplateColumns: "1fr 1fr",
            },
        }
    );

    return (
        <Page title="Specialisaties" shortTitle="Specialisaties">
            <div className={gridDivCss}>
                <SpecialisatiesGridItem href="/specialisaties/baby-kinderrevalidatie"           imgsrc="/images/specialisaties/004.jpg" title="Baby- en kinderrevalidatie" />
                <SpecialisatiesGridItem href="/specialisaties/bobath-therapie"                  imgsrc="/images/specialisaties/006.jpg" title="Bobath-therapie" />
                <SpecialisatiesGridItem href="/specialisaties/vkoh-hendrickx-methode"           imgsrc="/images/home/home_08.jpg"       title="VKOH - Hendrickx Methode" />
                <SpecialisatiesGridItem href="/specialisaties/pre-en-postnatale-kinesitherapie" imgsrc="/images/specialisaties/015.jpg" title="Pre- en postnatale kinesitherapie" />
                <SpecialisatiesGridItem href="/specialisaties/kinesiotaping"                    imgsrc="/images/specialisaties/002.jpg" title="Kinesiotaping" />
                <SpecialisatiesGridItem href="/specialisaties/lymfedrainage"                    imgsrc="/images/specialisaties/007.jpg" title="Lymfedrainage" />
                <SpecialisatiesGridItem href="/specialisaties/schrijfmotoriek"                  imgsrc="/images/home/home_03.jpg"       title="Schrijfmotoriek" />
            <SpecialisatiesGridItem href="/specialisaties/psychomotoriek"                       imgsrc="/images/specialisaties/014.jpg" title="Psychomotoriek" />
            </div>
        </Page>
    );

};
