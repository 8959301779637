import React from "react";
import ContactInfo from "./ContactInfo";
import AddressInfo from "./AddressInfo";
import SocialIconsContainer from "./SocialIconsContainer";
import { css } from "glamor";

export default (props) => {

    let footerCss = css({
        backgroundColor: "#eeefe1",
        padding: "30px 0px",
        fontSize: "0.8em",
        lineHeight: "1.8em",
        color: "#333",
    });

    let footerContainerCss = css(
        {
            maxWidth: "875px",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "144px 144px",
        },
        {
            "@media(max-width: 900px)": {
                paddingLeft: "20px",
                paddingRight: "20px",
            },
        },
        {
            "@media(max-width: 640px)": {
                textAlign: "center",
                backgroundImage: "none",
            },
        }
    );

    let copyrightCss = css({
        textAlign: "center",
        fontSize: "0.75em",
        padding: "44px",
    });

    let currentYear = new Date().getFullYear();

    let afsprakenCss = css(
        {
            paddingTop: "0",
            marginTop: "0",
            float: "right",
            width: "360px",
            paddingRight: "100px",
        },
        {
            "@media(max-width: 875px)": {
                textAlign: "center",
                float: "none",
                width: "100%",
                maxWidth: "360px",
                margin: "0px auto",
                paddingRight: "0px",
                paddingTop: "20px",
                paddingBottom: "0px",
            },
        }
    );

    return (
        <footer>
            <div className={footerCss}>
                <div className={footerContainerCss}>
                    <SocialIconsContainer />
                    <div className={afsprakenCss}>
                        <p style={{ marginTop: 0 }}>
                            <strong>Uw afspraak</strong>
                            <br />
                            Consultaties en huisbezoeken enkel op afspraak.
                        </p>
                        <p>
                            Wij hanteren voor al onze behandelingen de
                            wettelijke tarieven (overeenkomstig het RIZIV).
                            Indien u een voorschrift van uw huisarts of
                            specialist heeft, krijgt u als patiënt (of als
                            ouder van een patiënt ) de maximale
                            terugbetaling via uw mutualiteit.
                        </p>
                    </div>
                    <AddressInfo />
                    <ContactInfo />
                </div>
            </div>
            <p className={copyrightCss}>
                &copy; Copyright {currentYear} Isabel Lecluyse
            </p>
        </footer>
    );

};
