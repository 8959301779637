import React from "react";
import Page from "../components/Page";
import { css } from "glamor";

export default (props) => {

    let buttonCss = css({
        letterSpacing: 1,
        marginBottom: 64,
        color: "white",
        height: "44px",
        backgroundColor: "#7c833b",
        border: 0,
        textAlign: "center",
        lineHeight: "44px",
        cursor: "pointer",
        maxWidth: 240,
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "30px",
    });

    let linkCss = css({
        textDecoration: "none",
        textTransform: "uppercase",
        color: "white",
    });

    let maxWidthCss = css({
        maxWidth: "875px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
    });

    let containerCss = css({
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        maxWidth: 875,
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
    });

    let containerBackground = css(
        {
            backgroundColor: "#eeefe1",
            marginTop: 64,
            paddingTop: 44,
            paddingBottom: 44,
        },
        {
            "@media(max-width: 875px)": {
                paddingTop: 22,
                paddingBottom: 22,
            },
        }
    );

    let itemLeftCss = css(
        {
            flex: "0 1 auto",
            width: "calc(25% - 1em)",
            textAlign: "center",
        },
        {
            "@media(max-width: 875px)": {
                width: "calc(25% - 1em - 20px)",
                paddingLeft: "20px",
            },
        },
        {
            "@media(max-width: 640px)": {
                flex: "0 1 auto",
                width: "100%",
                paddingLeft: "20px",
                paddingRight: "20px",
            },
        }
    );

    let itemRightCss = css(
        {
            flex: "0 1 auto",
            width: "calc(75% - 1em)",
        },
        {
            "@media(max-width: 875px)": {
                width: "calc(75% - 1em - 20px)",
                paddingRight: "20px",
            },
        },
        {
            "@media(max-width: 640px)": {
                flex: "0 1 auto",
                width: "100%",
                paddingLeft: "20px",
                paddingRight: "20px",
            },
        }
    );

    let imageCss = css(
        {
            minWidth: "100%",
            maxWidth: "100%",
            marginTop: "16px",
        },
        {
            "@media(max-width: 640px)": {
                maxWidth: "50%",
                minWidth: "50%",
            },
        }
    );

    let photoDivCss = css(
        {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            padding: "0px",
        },
        {
            "@media(max-width: 875px)": {
                display: "block",
                paddingTop: "22px",
                paddingBottom: "22px",
                marginLeft: "auto",
                marginRight: "auto",
            },
        },
        {
            "@media(max-width: 640px)": {
                display: "block",
                paddingTop: "22px",
                paddingBottom: "22px",
                marginLeft: "auto",
                marginRight: "auto",
            },
        }
    );

    let photoDivItemCss = css(
        {
            "@media(max-width: 875px)": {
                width: "75%",
                paddingTop: "22px",
                paddingBottom: "22px",
                marginLeft: "auto",
                marginRight: "auto",
            },
        },
        {
            "@media(max-width: 640px)": {
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
            },
        }
    );

    let photoCss = css(
        {
            width: "95%",
        },
        {
            "@media(max-width: 875px)": {
                width: "100%",
            },
        },
    );

    return (
        <Page title="" shortTitle="Welkom" width="100%">
            <div style={{ paddingTop: "1.5em" }}>
                <div className={containerCss}>
                    <div className={itemLeftCss}>
                        <img
                            alt="Isabel Lecluyse"
                            src={
                                process.env.PUBLIC_URL +
                                "/images/isabel-lecluyse.jpg"
                            }
                            className={imageCss + ' shadow'}
                        />
                    </div>
                    <div className={itemRightCss}>
                        <p>Hallo,</p>
                        <p>Ik ben Isabel Lecluyse, fiere mama van Cédric, Alex en Laurence. Ik studeerde MoReKi (revalidatiewetenschappen en kinesitherapie) in Leuven, gevolgd door een postgraduaat Pediatrische Kinesitherapie.</p>
                        <p>Om op de hoogte te zijn van nieuwe technieken volg ik regelmatig bijkomende opleidingen en lezingen zoals VKOH-Hendrickx, Bobath, ademhalingskine, lymfedrainage, …</p>
                        <p>Daarnaast kan je me vinden op het tennisveld, in het zwembad of loop ik ergens tussen de Bellegemse velden rond.</p>
                        <p>Groetjes,</p>
                        <p>Isabel</p>
                    </div>
                </div>
            </div>

            <div className={containerBackground}>
                <div className={containerCss}>
                    <div className={photoDivCss}>
                        <div className={photoDivItemCss}>
                            <img
                                className={photoCss + ' shadow'}
                                alt=""
                                src={process.env.PUBLIC_URL + "/images/specialisaties/011.jpg"}
                            />
                        </div>
                        <div className={photoDivItemCss}>
                            <img
                                className={photoCss + ' shadow'}
                                alt=""
                                src={process.env.PUBLIC_URL + "/images/specialisaties/012.jpg"}
                            />
                        </div>
                        <div className={photoDivItemCss}>
                            <img
                                className={photoCss + ' shadow'}
                                alt=""
                                src={process.env.PUBLIC_URL + "/images/specialisaties/009.jpg"}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={maxWidthCss}>
                <p style={{ textAlign: "center", paddingTop: 56 }}>
                    Meer informatie nodig of wil je een afspraak maken?
                </p>
                <p className={buttonCss}>
                    <a href="/contact" className={linkCss}>
                        Contacteer me
                    </a>
                </p>
            </div>
        </Page>
    );

};
