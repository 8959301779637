import React from "react";
import TwoColumnPage from "../components/TwoColumnPage";

export default (props) => {
    return (
        <TwoColumnPage
            title="Praktische Info"
            shortTitle="Praktische Info"
            imageSrc={process.env.PUBLIC_URL + "/images/specialisaties/010.jpg"}
            imageAlt="#staysafe"
        >
            <h2>Corona-maatregelen<br/>#samentegencorona #blijfgezond</h2>
            <ul>
                <li>Ben je ziek, in contact geweest met een COVID 19-patiënt of zit je in quarantaine? Dan vraag ik je vriendelijk jouw afspraak te annuleren.</li>
                <li>Probeer niet vroeger dan 5min op voorhand hier te zijn. Wacht even in de wagen of buiten. Bij slecht weer kan je ook in de wachtzaal wachten, rekening houdend met de social distancing regels.</li>
                <li>Kom (indien mogelijk) alleen naar de afspraak.</li>
                <li>Draag altijd je mondmasker en ontsmet je handen bij het binnenkomen en het naar buitengaan. Ontsmettingsalcohol staat ter beschikking in de wachtruimte.</li>
                <li>Voorlopig gebruik ik geen tafelhoes op de behandeltafel dus indien nodig, mag je altijd zelf een handdoek meenemen.</li>
                <li>Na iedere patiënt wordt alles ontsmet en verlucht ik de ruimte.</li>
            </ul>
            <h2>Wat meebrengen naar de eerste afspraak?</h2>
            <ul>
                <li>Identiteitskaart</li>
                <li>Voorschrift van de arts dat maximum 2 maanden geleden voorgeschreven werd</li>
                <li>Klevertje van de mutualiteit</li>
                <li>Indien van toepassing: verslagen van de specialist of van medische beeldvorming</li>
            </ul>
        </TwoColumnPage>
    );
};
