import React from "react";
import SpecialisatiesPage from "../../components/SpecialisatiesPage";

export default (props) => {
    return (
        <SpecialisatiesPage
            title="Kinesiotaping"
            shortTitle="Kinesiotaping"
            imageSrc={process.env.PUBLIC_URL + "/images/specialisaties/002.jpg"}
            imageAlt="Kinesiotaping"
        >
            <p>
                Taping waarbij we gebruik maken van elastische tape met als
                doel: pijndemping, ondersteuning van spieren en gewrichten,
                houdingscorrectie, oedeemreductie, …
            </p>
        </SpecialisatiesPage>
    );
};
