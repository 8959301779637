import React, { useState } from "react";
import HeaderLink from "./HeaderLink";
import { css } from "glamor";

export default (props) => {

    const [tocIsHidden, setTocIsHidden] = useState(true);

    const toggleToc = () => {
        setTocIsHidden(!tocIsHidden);
        return false;
    };

    let headerCss = css(
        {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "white",
            zIndex: 1200,
            cursor: "pointer",
        },
        {
            "@media(max-width: 900px)": {
                height: 100,
                borderBottom: "1px solid #eeefe1",
            },
        },
        {
            "@media(max-width: 640px)": {
                height: "64px",
                backgroundSize: "50px auto",
                borderBottom: "1px solid #eeefe1",
            },
        }
    );

    let headerWrapperCss = css(
        {
            width: "875px",
            margin: "0 auto",
        },
        {
            "@media(max-width: 900px)": {
                backgroundPosition: "20px center",
                height: 100,
                paddingLeft: "20px",
                paddingRight: "20px",
            },
        },
        {
            "@media(max-width: 640px)": {
                height: 64,
                backgroundSize: "44px 44px",
            },
        }
    );

    let headerLinksWrapperCss = css(
        {
            height: 44,
            backgroundColor: "#eeefe1",
        },
        {
            "@media(max-width: 900px)": {
                height: 0,
            },
        },
        {
            "@media(max-width: 640px)": {
                height: 64,
                backgroundColor: "rgba(0,0,0,0)",
            },
        }
    );

    let headerH1Css = css(
        {
            position: "relative",
            top: 0,
            height: "80px",
            lineHeight: "80px",
            margin: 0,
            padding: 0,
            fontSize: "2em",
            color: "#7c833b",
            fontWeight: "bold",
            maxWidth: "450px",
            fontFamily: 'Bradley Hand Bold',
        },
        {
            "@media(max-width: 640px)": {
                lineHeight: "70px",
                fontSize: "1.75em",
            },
        }
    );

    let headerH2Css = css(
        {
            position: "relative",
            top: -20,
            fontSize: "1em",
            lineHeight: "1",
            textTransform: "none",
            color: "black",
            fontWeight: "normal",
        },
        {
            "@media(max-width: 640px)": {
                display: "none",
            },
        }
    );

    let iconTocCss = css(
        {
            position: "absolute",
            top: "0",
            right: "10px",
            margin: "0px",
            padding: "0px",
            paddingRight: "10px",
            cursor: "pointer",
            backgroundImage: tocIsHidden
                ? "url(" + process.env.PUBLIC_URL + "/images/icon-toc-olive.svg)"
                : "url(" + process.env.PUBLIC_URL + "/images/icon-close-olive.svg)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "44px 44px",
            height: "100px",
            width: "44px",
            display: "none",
            zIndex: 1111,
        },
        {
            "@media(max-width: 900px)": {
                display: "inline",
            },
        },
        {
            "@media(max-width: 640px)": {
                height: "64px",
                width: "44px",
                paddingRight: 0,
            },
        }
    );

    let headerUlCss = css(
        {
            width: "875px",
            margin: "0 auto",
            padding: 0,
            paddingRight: "10px",
            overflow: "hidden",
            height: 44,
            textAlign: "left",
        },
        {
            "@media(max-width: 900px)": {
                display: tocIsHidden ? "none" : "inline",
                position: "fixed",
                width: "100vw",
                height: "100vh",
                left: 0,
                top: "100px",
                paddingTop: "0px",
                zIndex: 999,
                backgroundColor: "white",
                overflow: "auto",
                borderTop: "1px solid #ccc",
            },
        },
        {
            "@media(max-width: 640px)": {
                top: "64px",
                paddingTop: "0px",
            },
        }
    );

    return (
        <header className={headerCss}>
            <div className={headerWrapperCss}>
                <h1 className={headerH1Css}>
                    <a href="/" style={{ color: "#7c833b" }}>{props.title}</a>
                </h1>
                <h2 className={headerH2Css}>
                    Kinesitherapie voor kinderen
                </h2>
            </div>
            <div className={iconTocCss} onClick={toggleToc} />
            <div className={headerLinksWrapperCss}>
                <ul className={headerUlCss}>
                    <HeaderLink action={toggleToc} exact url="/" label="Welkom" />
                    <HeaderLink action={toggleToc} url="/praktijk" label="De Praktijk" />
                    <HeaderLink action={toggleToc} url="/specialisaties" label="Specialisaties" />
                    <HeaderLink action={toggleToc} url="/praktische-info" label="Praktische Info" />
                    <HeaderLink action={toggleToc} url="/contact" label="Contact" />
                </ul>
            </div>
        </header>
    );
};
