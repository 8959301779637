import React from "react";
import { css } from "glamor";

export default (props) => {

    let gridItemCss = css(
        {
            width: "100%",
            height: "240px",
            textAlign: "center",
            backgroundColor: "white",
        },
        {
            "@media(max-width: 875px)": {
                width: "100%",
                marginBottom: "20px",
            },
        },
        {
            "@media(max-width: 380px)": {
                width: "100%",
                marginBottom: "20px",
            },
        }
    );

    let gridItemImgCss = css(
        {
            width: "50%",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "4px",
        },
        {
            "@media(max-width: 875px)": {
                width: "50%",
                marginBottom: "4px",
            },
        },
        {
            "@media(max-width: 380px)": {
                width: "90%",
                marginBottom: "4px",
            },
        }
    );

    return (
        <div className={gridItemCss}>
            <a href={props.href}>
                <img className={gridItemImgCss + ' shadow'} src={process.env.PUBLIC_URL + props.imgsrc} alt={props.title} />
                {props.title}
            </a>
        </div>
    );

};
