import React from "react";
import { css } from "glamor";

export default (props) => {

    let pCss = css({
        "@media(max-width: 875px)": {
            textAlign: "center",
        },
    });

    return (
        <p className={pCss}>
            <nobr><strong>Isabel Lecluyse</strong></nobr><br />
            Kinesitherapie voor kinderen<br />
            <nobr>Munkendoornstraat 294</nobr><br />
            <nobr>8510 Bellegem (Kortrijk)</nobr>
        </p>
    );

};
