import React from "react";
import { SocialIcon } from "react-social-icons";
import { css } from "glamor";

export default (props) => {

    let iconCss = css({
        marginLeft: 8,
    });

    return (
        <span className={iconCss}>
            <SocialIcon url={props.url} color="#7c833b" style={{ height: 44, width: 44 }} />
        </span>
    );

};
