import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "glamor";

export default (props) => {

    const handleClick = (event) => {
        var targetChildren = event.target.children;
        if (targetChildren && targetChildren.length > 0) {
            var firstChild = targetChildren[0];
            window.location.href = firstChild.href;
        }
        props.action();
    }

    let liCss = css(
        {
            display: "inline-block",
            marginLeft: 0,
            color: "#666",
            height: "43px",
            lineHeight: "44px",
            padding: "0 16px",
            fontSize: "1em",
            fontFamily: "Lato, sans-serif",
            textTransform: "lowercase",
            ":hover": {
                backgroundColor: "#7c833b",
                color: "white",
            },
            ":hover a": {
                backgroundColor: "#7c833b",
                color: "white",
            },
            "& .active": {
                textDecoration: "none",
                color: "#7c833b",
                fontWeight: "bold",
            },
        },
        {
            "@media(max-width: 900px)": {
                display: "block",
                width: "100vw",
                textAlign: "center",
                lineHeight: "64px",
                padding: 0,
                borderBottom: "1px solid #ccc",
                fontSize: "1.25em",
                height: 64,
            },
        }
    );

    let aCss = css(
        {
            textDecoration: "none",
            fontWeight: "normal",
            color: "rgb(128, 128, 128)",
            ":hover": {
                color: "white",
            },
        },
        {
            "@media(max-width: 900px)": {
                lineHeight: "44px",
            },
        }
    );

    return (
        <li onClick={handleClick} className={liCss}>
            <NavLink activeClassName="active" exact={props.exact} to={props.url} className={`${aCss}`}>
                {props.label}
            </NavLink>
        </li>
    );

};
