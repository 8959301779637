import React from "react";
import SpecialisatiesPage from "../../components/SpecialisatiesPage";

export default (props) => {
    return (
        <SpecialisatiesPage
            title="Schrijfmotoriek"
            shortTitle="Schrijfmotoriek"
            imageSrc={process.env.PUBLIC_URL + "/images/home/home_03.jpg"}
            imageAlt="Schrijfmotoriek"
        >
            <p>
                Een belangrijk aspect in het dagelijks leven van een schoolgaand
                kind is het schrijven. Slordig, moeilijk leesbaar, te klein, te
                traag, pijn bij het schrijven, veel moeite,…leidt allemaal tot
                frustraties. Dit zijn problemen die we reeds in de kleuterklas
                kunnen opmerken en zich daar ook al kunnen manifesteren. Op tijd
                ingrijpen is dus de boodschap.
            </p>
            <p>
                Schrijven is een complexe vaardigheid die zowel motorische - als
                cognitieve vaardigheden vereist. Het juiste materiaal, een goede
                zithouding en correcte instructies kunnen reeds veel verschil
                maken bij het schrijven.
            </p>
            <p>
                De therapie kan dus uit verschillende onderdelen bestaan
                afhankelijk van de noodzaak.
            </p>
        </SpecialisatiesPage>
    );
};
