import React from "react";
import Page from "./Page";
import { css } from "glamor";

export default (props) => {

    let containerCss = css({
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
    });

    let itemLeftCss = css(
        {
            flex: "0 1 auto",
            width: "calc(30% - 1em)",
        },
        {
            "@media(max-width: 800px)": {
                flex: "0 1 auto",
                width: "calc(30% - 1em)",
            },
        },
        {
            "@media(max-width: 640px)": {
                flex: "0 1 auto",
                width: "100%",
            },
        }
    );

    let itemRightCss = css(
        {
            flex: "0 1 auto",
            width: "calc(70% - 1em)",
        },
        {
            "@media(max-width: 800px)": {
                flex: "0 1 auto",
                width: "calc(70% - 1em)",
            },
        },
        {
            "@media(max-width: 640px)": {
                flex: "0 1 auto",
                width: "100%",
            },
        }
    );

    let imageCss = css({
        minWidth: "100%",
        maxWidth: "100%",
        marginTop: "16px",
        marginBottom: "16px",
    });

    return (
        <Page title={props.title} shortTitle={props.shortTitle}>
            <div className={containerCss}>
                <div className={itemLeftCss}>
                    {props.imageSrc !== "" && <img
                        alt={props.imageAlt}
                        src={props.imageSrc}
                        className={imageCss + ' shadow'}
                    />}
                </div>
                <div className={itemRightCss}>
                    {props.children}
                    <p className="back-link">
                        <a href="/specialisaties">Terug naar specialisaties</a>
                    </p>
                </div>
            </div>
        </Page>
    );

};
