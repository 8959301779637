import React from "react";
import { css } from "glamor";

export default (props) => {

    let pCss = css({
        "@media(max-width: 875px)": {
            textAlign: "center",
        },
    });

    return (
        <p className={pCss}>
            <nobr>Telefoon: <a href="callto:+3256320982" style={{ color: "#7c833b" }}>+32 56 32 09 82</a></nobr>
            <br />
            <nobr>GSM: <a href="callto:+32476837931" style={{ color: "#7c833b" }}>+32 476 83 79 31</a></nobr>
            <br />
            <nobr>Email: <a href="mailto:isabel.lecluyse@telenet.be" style={{ color: "#7c833b" }}>isabel.lecluyse@telenet.be</a></nobr>
        </p>
    );

};