import React from "react";
import SpecialisatiesPage from "../../components/SpecialisatiesPage";

export default (props) => {
    return (
        <SpecialisatiesPage
            title="Psychomotoriek"
            shortTitle="Psychomotoriek"
            imageSrc={process.env.PUBLIC_URL + "/images/specialisaties/014.jpg"}
            imageAlt="Psychomotoriek"
        >
            <p>
                Het woord psychomotoriek bestaat uit twee delen: psycho wat
                geest betekent en motoriek wat bewegen betekent. Met andere
                woorden, psychomotoriek is dus het samengaan van geest en
                beweging. Cfr de uitdrukking: al doende leert men! Onze
                bewegingen worden gestuurd en gepland vanuit het denken, op die
                manier kunnen ze gericht zijn.
            </p>
            <p>
                Een goede ontwikkeling van de motoriek (rollen, kruipen,
                stappen, lopen,…) vormt de basis van de meer gecompliceerde
                bewegingen, zoals bv fietsen, zwemmen,…
            </p>
            <p>
                Maar niet alleen de grofmotorische vaardigheden (lopen,
                turnen,…) zijn van belang, ook de fijnmotorische vaardigheden
                kom al vrij vroeg aan bod. Al vanaf de kleuterschool worden
                kinderen geconfronteerd met beide vaardigheden, denk maar aan
                knippen, plakken,…
            </p>
            <p>
                Psychomotoriek is een therapievorm die zich niet alleen op de
                ontwikkeling van de grof- en fijnmotorische vaardigheden focust,
                maar ook op de vaardigheden zoals lateralisatie,
                schrijfmotoriek, ruimtelijke ordening, structuratie,
                concentratie, werkhouding, faalangst,…
            </p>
            <p>
                Problemen in de motoriek kunnen een negatieve invloed hebben op
                het zelfbeeld en de leermogelijkheden van een kind. Vroeg
                ingrijpen is dus de boodschap!
            </p>
            <p>
                Wat houdt de therapie in? Aan de hand van bewegingen ontdekken
                de kinderen hun eigen lichaam en leren ze hun eigen durven en
                kunnen in te schatten. Ze leren aan de hand van
                bewegingservaringen hun lichaam beter te kennen als ook de
                positie van hun lichaam in de ruimte, besef van links en
                rechts,…basispeilers die nodig zijn om te komen tot de schoolse
                vaardigheden zoals lezen en schrijven!
            </p>
            <p>
                De psychomotoriek focust zich op de ontwikkeling van het kind in
                zijn geheel!
            </p>
            <p>
                Voor wie? Kleutertjes die problemen hebben met kleuren, knippen,
                plakken, flesje openen,… maar ook kinderen die problemen hebben
                met lezen (trage lezers, lezen in stukjes), schrijven (te klein,
                te groot,b/d omkering, spiegelen,…), concentratie problemen,
                oriëntatie problemen, rekenproblemen…
            </p>
        </SpecialisatiesPage>
    );
};
